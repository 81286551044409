<template>
 <div class="w-3/5 mx-auto my-20 py-5 bg-white rounded-md shadow-md">
  <div
   class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 flex justify-between items-center"
  >
   <h3 class="text-lg font-medium leading-6 text-gray-900">
    {{ quiz && quiz.subject ? quiz.subject : "" }}
   </h3>
   <button
    @click="$router.push('/training/quizzes')"
    type="button"
    class="inline-flex items-center rounded-full border border-transparent bg-gray-500 p-1 text-white shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
   >
    <XIcon class="h-5 w-5" aria-hidden="true" />
   </button>
  </div>
  <div class="bg-gray-50 sm:pt-8">
   <div class="bg-white sm:pb-8">
    <div class="relative">
     <div class="absolute inset-0 h-1/2 bg-gray-50" />
     <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-4xl">
       <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
        <div class="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
         <dt class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 capitalize">
          {{ $t("score") }}
         </dt>
         <dd class="order-1 sm:text-lg md:text-xl lg:text-3xl font-bold tracking-tight text-indigo-600">
          <p
           :class="[
            evaluation.passed ? 'text-green-500' : 'text-red-500',
            'mt-2 items-center order-1 sm:text-lg md:text-xl lg:sm:text-lg md:text-xl lg:text-3xl font-bold tracking-tight gap-1',
           ]"
          >
           <font-awesome-icon v-if="evaluation.passed" icon="star" />
           <font-awesome-icon v-else icon="star-half-stroke" />
           <span class="items-center">{{ (evaluation.score_percent * 100).toFixed(0) }}%</span>
          </p>
         </dd>
        </div>
        <div
         class="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r"
        >
         <dt class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 capitalize">
          {{ $t("result") }}
         </dt>
         <dd class="order-1 sm:text-lg md:text-xl lg:text-3xl font-bold tracking-tight text-indigo-600">
          <p
           :class="[
            evaluation.passed ? 'text-green-500' : 'text-red-500',
            'mt-2 items-center order-1 sm:text-lg md:text-xl lg:text-3xl font-bold tracking-tight gap-1',
           ]"
          >
           <span v-if="evaluation.passed" class="items-center">Aproved</span>
           <span v-else class="items-center">Reproved</span>
          </p>
         </dd>
        </div>
        <div class="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
         <dt class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 capitalize">
          {{ $t("time") }}
         </dt>
         <dd class="order-1 sm:text-lg md:text-xl lg:text-3xl font-bold tracking-tight text-indigo-600">
          <p :class="['mt-2 flex items-center justify-center text-center gap-1']">
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-8 h-8"
           >
            <path
             fill-rule="evenodd"
             d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
             clip-rule="evenodd"
            />
           </svg>

           {{ Math.floor(evaluation.time_seconds / 60) }}<span class="sm:text-md md:text-lg lg:text-xl mt-2">min</span> :
           {{ evaluation.time_seconds - Math.floor(evaluation.time_seconds / 60) * 60
           }}<span class="sm:text-md md:text-lg lg:text-xl mt-2">sec</span>
          </p>
         </dd>
        </div>
       </dl>
      </div>
     </div>
    </div>
   </div>
  </div>
  <div v-if="answers && answers.length > 0">
   <div v-for="(question, idx) in answers" :key="question.id">
    <div class="mx-20 my-5">
     <h1 class="text-xl font-medium">{{ question.group }}</h1>
     <h2 class="text-gray-600">Question {{ idx + 1 }}</h2>
     <div class="rounded-md">
      <div class="px-20 py-10 border">
       <check-multiple-answers
        :type="question.questionType"
        :question="question.question.question"
        :answers="question.choices"
        :valid="question.valid"
        :resultOnly="true"
       />
      </div>
     </div>
    </div>
    <div class="relative py-5">
     <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300" />
     </div>
     <div class="relative flex justify-between mx-20"></div>
    </div>
   </div>
  </div>
  <div v-else>
   <div class="px-20 py-10 border">
    <h1>Quiz has no answers</h1>
   </div>
  </div>
  <div v-if="this.quiz" class="flex justify-center">
   <button
    @click="
     openNewQuiz(
      quiz.id,
      quiz.subject,
      `Start quiz ${quiz.subject}`,
      'You are about to start the quiz. Are you ready to continue?',
      'Continue',
      'Go back'
     )
    "
    class="capitalize rounded group relative w-full mx-20 flex justify-center py-2 px-4 border text-sm font-medium shadow-md text-white body__button focus:outline-none"
   >
    {{ $t("repeat") }}
   </button>
  </div>
 </div>
</template>

<script>
import CheckMultipleAnswers from "../../components/CheckMultipleAnswers.vue";
import { XIcon } from "@heroicons/vue/solid";
import axios from "axios";
export default {
 props: ["uuid"],
 components: { CheckMultipleAnswers, XIcon },
 data() {
  return {
   quiz: {},
   evaluation: {},
   answers: {},
  };
 },
 methods: {
  async openNewQuiz(id, subject, title, confirmationMessage, confirmButton, goBack) {
   let href = `/training/quiz/${id}/${subject}`;
   // const r = await this.$refs.askConfirmationDialog.show({
   //   title: title,
   //   message: confirmationMessage,
   //   okButton: confirmButton,
   //   cancelButton: goBack,
   //   action: "",
   // });
   let url = "v1/training/quiz-evaluations";
   let data = {
    quiz_id: id,
   };
   axios.post(`${this.$cookie.getCookie("API")}/api/${url}`, data).then((res) => {
    console.log("file: TrainingQuizzes.vue ~ line 216 ~ axios.post ~ res", res.data);
    this.$router.push(href + "/" + res.data.uuid);
   });
  },
  getData() {
   let url = `${this.$cookie.getCookie("API")}/api/v1/training/quiz-evaluations/${this.uuid}`;
   axios
    .get(url)
    .then((res) => {
     console.log("file: QuizResult.vue ~ line 22 ~ axios.get ~ res", res.data);
     this.quiz = res.data.quiz ? res.data.quiz : null;
     this.evaluation = res.data.evaluation;
     this.answers = res.data.answers;
    })
    .catch((error) => {
     this.errorHandling(error);
    });
  },
 },
 mounted() {
  this.getData();
 },
};
</script>

<style></style>
