<template>
  <div class="">
    <label class="" for="answer" v-html="this.question"></label>
    <fieldset class="space-y-5">
      <legend class="sr-only">Answers</legend>
      <div
        :class="[resultOnly ? 'grid grid-rows-1 grid-flow-col gap-4' : '', '']"
        class=""
      >
        <div
          v-for="answer in replies"
          :key="answer.id"
          :class="[
            resultOnly ? 'col-span-5 ' : '',
            'px-1 justify-start flex items-center gap-5',
          ]"
        >
          <div class="h-7">
            <input
              :required="this.mandatory ? true : false"
              :id="answer.reply ? answer.reply : answer.label"
              aria-describedby="comments-description"
              :name="answer.reply ? answer.reply : answer.label"
              :type="this.type === 'multipleChoice' ? 'checkbox' : 'radio'"
              :class="[
                answer.selected
                  ? answer.selected == answer.expect_selected
                    ? 'focus:ring-green-500 text-green-600 border-green-300'
                    : 'focus:ring-red-500 text-red-600 border-red-300'
                  : 'focus:ring-green-500 text-green-600 border-green-300',
                'h-4 w-4 rounded',
              ]"
              :checked="answer.checked || answer.selected"
              :disabled="resultOnly ? resultOnly : false"
              @change="
                !resultOnly
                  ? checkAnswer(answer.id, answer.quiz_question_id)
                  : ''
              "
            />
          </div>
          <div class="">
            <label
              :for="answer.reply ? answer.reply : answer.label"
              :class="[
                answer.selected
                  ? answer.selected == answer.expect_selected
                    ? 'text-green-700'
                    : 'text-red-700'
                  : 'text-gray-700',
                'font-medium',
              ]"
              class="font-medium text-gray-700"
              >{{ answer.reply ? answer.reply : answer.label }}</label
            >
          </div>
        </div>
        <div
          v-if="resultOnly"
          class="row-span-3 items-center flex justify-center"
        >
          <svg
            v-if="valid"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-20 h-20 text-green-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-20 h-20 text-red-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "CheckMultipleAnswers",
  props: [
    "question",
    "mandatory",
    "id",
    "type",
    "answers",
    "resultOnly",
    "valid",
  ],
  data() {
    return {
      checked: false,
      replyList: {},
    };
  },
  methods: {
    checkAnswer(id, questionId) {
      console.log(
        "file: CheckMultipleAnswers.vue ~ line 46 ~ checkAnswer ~ id",
        id
      );
      console.log(
        "file: CheckMultipleAnswers.vue ~ line 47 ~ checkAnswer ~ questionId",
        questionId
      );
      if (this.type !== "multipleChoice") {
        this.replies.map((res) => {
          res.id == id ? (res.checked = true) : (res.checked = false);
        });
      } else {
        this.replies.map((res) => {
          res.id == id ? (res.checked = !res.checked) : "";
        });
      }
      this.$emit("quizReply", questionId, this.replies);
    },
  },
  computed: {
    replies() {
      let list = this.answers;
      for (let index = 0; index < this.answers.length; index++) {
        list[index]["checked"] = false;
      }
      return list;
    },
  },
};
</script>
